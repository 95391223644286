import React, { Component } from 'react';
import axios from 'axios';
import styles from './Info.module.css';
import constants from './constants';

export default class Section extends Component {
    constructor(props){
        super(props);
        this.state = {
            data : {}

        }
    }
    componentDidMount() {
        axios
            .get(constants.INFO_URL)
            .then(response => {

                this.setState({
                    data:response.data.data[0]
                })

            })
            .catch(error => console.log(error));
        }

    render() {
        return (<div className="container heading">
            <div className={styles.info}>
                <div dangerouslySetInnerHTML={ {__html: this.state.data.info} } />
                
            </div>
        </div>
        )
    }

}
