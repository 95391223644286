import React, { Component } from 'react';

import styles from './ServiceCard.module.css';

export default class ServiceCard extends Component {
    componentDidMount() {
        
    }

    render() {
        let image = "url('" + this.props.image + "')";
        return (
            <div className={styles.cardContainer} style={{ backgroundImage: image }}>
                <div className={styles.textdiv} >
                    <h2>{this.props.name}</h2>
                </div>
                <div className={styles.overlay}>
                    {this.props.description}
                </div>
            </div>
        )
    }
}


