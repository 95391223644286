import React, { Component } from 'react'

export default class TopPadding extends Component {
    constructor(props) {
        super(props);
        this.style = {
            paddingTop: this.props.padding + 'px'
        }
    }
    render() {
        return (
            <div style={this.style}>
                {this.props.children}
            </div>
        )
    }
}