import React, { Component } from 'react';

import styles from './TeamCard.module.css';
import cx from 'classnames';

export default class TeamCarde extends Component {
    constructor(props) {
        super(props);
        this.bg = {
            backgroundImage: `url(${this.props.image})`,
        };
    }

    componentDidMount() {
        //const wow = new WOW.WOW();
        //wow.init();
    }

    render() {

        return (
            <div className={cx(styles.guideCardContainer, "wow", "zoomIn", "shadow")}>
                <div style={this.bg} className={styles.imageBackground}>

                </div>
                <div className={styles.textdiv}>
                    <h2>{this.props.name}</h2>
                    <p className={styles.text}>{this.props.description}</p>
                </div>

            </div>


        )
    }
}


