import React, { Component } from 'react';
import { FaBars } from 'react-icons/fa';
import { FaTimes } from 'react-icons/fa';

export default class AppBar extends Component {
    constructor(props) {
        super(props);

        this.style = {
            trans: "navbar navbar-transparent",
            black: "navbar navbar-color "
        }

        this.state = {
            activeStyle: this.style.trans,
            scrollSize: 100,
            isMobile: false,
            menuClass: "collapse navbar-collapse"

        }
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = (event) => {
        let el = document.scrollingElement || document.documentElement;
        let scrollTop = el.scrollTop;
        //let scrollTop = event.srcElement.scrollingElement.scrollTop;

        if (scrollTop > this.state.scrollSize) {
            this.setState({
                activeStyle: this.style.black
            })
        } else {
            this.setState({
                activeStyle: this.style.trans
            })
        }
    }

    onNavClick = (event) => {
        event.preventDefault();
        let href = event.currentTarget.getAttribute("href");
        let el = document.getElementById(href);

        el.scrollIntoView({ block: "start", behavior: "smooth" });
        if (this.state.isMobile) {
            this.setState({
                menuClass: "collapse navbar-collapse open-drawer",

            })
        }

    }

    closeMenu = () => {
        this.setState({
            menuClass: "collapse navbar-collapse open-drawer",
            isMobile: true
        })
    }

    toggleMenu = () => {
        this.setState({
            menuClass: "open-drawer-active open-drawer",
            isMobile: true
        })

    }

    render() {
        return (
            <div className="header" >
                <nav className={this.state.activeStyle}>
                    <div>
                        <div className="navbar-header" >
                            <div className="menu-icon" onClick={this.toggleMenu}>

                                <div className="menu-open-icon">
                                    <FaBars />
                                </div>
                                <div className="mobile-menu-heading">
                                    <h3>Frauenzimmer</h3>
                                </div>

                            </div>
                            <div className={this.state.menuClass} id="custom-collapse">
                                <div className="closeicon" onClick={this.closeMenu}>
                                    <FaTimes />
                                </div>

                                <ul>
                                    <li>
                                        <a href="main" onClick={this.onNavClick} className="section-scroll">Home</a>
                                    </li>
                                    <li>
                                        <a href="team" onClick={this.onNavClick} className="section-scroll">Team</a>
                                    </li>
                                    <li>
                                        <a href="praxis" onClick={this.onNavClick} className="section-scroll">Praxis</a>
                                    </li>
                                    <li>
                                        <a href="service" onClick={this.onNavClick} className="section-scroll">Leistungen</a>
                                    </li>
                                    <li>
                                        <a href="map" onClick={this.onNavClick} className="section-scroll">Anreise</a>
                                    </li>
                                    <li>
                                        <a href="contact" onClick={this.onNavClick} className="section-scroll">Öffnungszeiten & Kontakt</a>
                                    </li>
                                    

                                </ul>
                            </div>
                        </div>

                    </div>
                </nav>
            </div>

        )
    }
}

