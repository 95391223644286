import React, { Component } from 'react';
import styles from './HeaderCarousel.module.css';
import SwipeableViews from 'react-swipeable-views';
import { faAngleDoubleRight, faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const styleRadioChecked = {
    border: '4px solid #84A4C5',
    boxShadow: 'rgba(255,255,255, 0.75) 0px 2px 12px 4px'
}

const styleRadioNotChecked = {
    border: ''
}
/*

 slide = {
                maintext: "",
                subtext: "",
                title: "",
                image: ""
            },
*/

export default class HeaderCarousel extends Component {
    constructor(props) {
        super(props);
       
        this.state = {
           
            index: 0
        }
    }

    componentDidMount

    getSlides = () => {
        var data = [];
        var scontent = {};

        for (var i = 0; i < this.props.slides.length; i++) {
            var slide = this.props.slides[i];

            var style = {
                background: `url(${slide.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "100vh",

            }
            
            if (slide.dark) {
                style = {
                    background: `
                        radial-gradient(circle, rgba(0,0,0,0.8) 100%, rgba(0,0,0,0) 100%), 
                        url(${slide.image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: "100vh",

                }

            }
            

            data.push(
                <div key={slide.id} className={styles.slider} style={style} >
                    <div className={styles.sliderContent} style={scontent}>
                        {this.getLeftArrow()}
                        <div className={styles.sliderContentText}>
                            <div className={styles.title}>{slide.title}</div>
                            <div className={styles.infotext}>{slide.maintext}</div>
                            <div className={styles.subtext}>{slide.subtext}</div>
                            <div className={styles.subsubtext}>{slide.subsubtext}</div>
                        </div>
                        {this.getRightArrow()}
                    </div>
                </div>

            )
        }
        return data;
    }



    getRightArrow = () => {
        var color = { color: 'white' };
        if (this.state.index === this.props.slides.length - 1) {
            color = {
                color: '#dcdee244'
            }
        }


        return (
            <div className={styles.arrow}>
                <FontAwesomeIcon icon={faAngleDoubleRight} onClick={this.nextSlide} style={color} />
            </div>
        )

    }

    getLeftArrow = () => {
        var color = { color: 'white' };
        if (this.state.index === 0) {
            color = {
                color: '#dcdee244'
            }
        }
        return (
            <div className={styles.arrow}>
                <FontAwesomeIcon icon={faAngleDoubleLeft} onClick={this.prevSlide} style={color} />
            </div>
        )
    }

    nextSlide = () => {
        var index = this.state.index + 1;
        if (index > this.props.slides.length - 1) {
            index = 0;
        }
        this.setState({
            index: index
        })
    }

    prevSlide = () => {
        var index = this.state.index - 1;
        if (index < 0) {
            index = this.props.slides.length - 1;
        }
        this.setState({
            index: index
        })
    }

    getRadios = () => {
        var data = [];

        for (var i = 0; i < this.props.slides.length; i++) {
            var name = "radio" + i;
            data.push(<input className={styles.input} type="radio" id={name} key={"r" + i} />)
        }
        return data;
    }

    getLabels = () => {
        var data = [];

        for (var i = 0; i < this.props.slides.length; i++) {
            var name = "radio" + i;
            var styleLocal = this.state.index === i ? styleRadioChecked : styleRadioNotChecked;
            data.push(<label data-id={i} htmlFor={name} style={styleLocal} className={styles.label} onClick={this.setPageNumber} key={"l" + i} />)
        }
        return data;
    }

    setPageNumber = (el) => {
        var index = Number(el.target.getAttribute("data-id"));
        console.log("index:" + index);
        this.setState({
            index: index
        })

    }

    render() {
        const { index } = this.state;
        return (
            <div className={styles.container}>
                <SwipeableViews enableMouseEvents index={index} onChangeIndex={(index => this.setState({ index: index }))}>
                    {this.getSlides()}
                </SwipeableViews>

                {this.getRadios()}
                <div className={styles.sliderPagination}>
                    {this.getLabels()}

                </div>
                
            </div>
        )
    }
}

