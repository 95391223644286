var base = "https://api.riedmann.rocks/frauenzimmer/";
module.exports ={
    
    BASE_URL: base ,
    TEAM_URL: base + "items/team?fields=*.*.*&status=published",
    TEAM_HEADER_URL: base + "items/team_header?fields=*.*.*&status=published",
    SERVICES_URL: base + "items/services?fields=*.*.*&status=published",
    SERVICES_HEADER_URL: base + "items/services_header?fields=*.*.*&status=published",
    TITLE_URL: base + "items/title_slider?fields=*.*.*&status=published",
    INFO_URL: base + "items/info?fields=*.*.*&status=published",
    PRAXIS_URL: base + "items/praxis?fields=*.*.*&status=published",
    
}