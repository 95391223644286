import { AnimatePresence, motion } from 'framer-motion';

import React, { Component } from 'react'
import './Loader.css';
import App from './components/App';

const pageVariants = {
    in: {
        opacity: 1,
        y: 0

    },
    out: {
        opacity: 0,

    }
}

const pageTransition = {
    duration: 1
}

const style = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh"
}


export default class Loader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }

    }

    click = () => {

        this.loadedDone();

    }

    loadedDone = () => {
        this.setState({
            loading: false
        })
    }


    getContent() {
        if (this.state.loading) {
            return (
                <AnimatePresence>
                    <motion.div style={style} exit={{ opacity: 0, duration: 1 }} variants={pageVariants} animate="in" initial="out" >
                        <img src="https://api.riedmann.rocks/frauenzimmer/assets/crd75a9dbmgcgkck?key=slider" onLoad={this.click} alt="hidden" hidden="true" />
                        <div className="lds-ripple"><div></div><div></div></div>
                    </motion.div>
                </AnimatePresence>);
        } else
            return (
                <motion.div transition={pageTransition} variants={pageVariants} animate="in" initial="out" >
                    <App />
                </motion.div>
            );
    }

    render() {
        return this.getContent();



    }

}
