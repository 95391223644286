import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import ServiceCard from './ServiceCard';
import axios from 'axios';
import constants from './constants';
import styles from './ServiceGrid.module.css';

export default class ServiceGrid extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		axios
			.get(constants.SERVICES_HEADER_URL)
			.then((response) => {
				this.setState({
					title: response.data.data[0].title,
					description: response.data.data[0].description
				});
			})
			.catch((error) => console.log(error));

		axios
			.get(constants.SERVICES_URL)
			.then((response) => {
				this.setState({
					services: response.data.data
				});
			})
			.catch((error) => console.log(error));
	}

	getGrid = () => {
		if (!this.state.services) return;
		var data = [];
		for (var i = 0; i < this.state.services.length; i++) {
			var service = this.state.services[i];

			let image = '';
			if (service.image) {
				image = service.image.data.thumbnails[7].url;
			}

			data.push(
				<Grid item xs={12} sm={12} md={6} key={service.id}>
					<ServiceCard name={service.title} description={service.description} image={image} />
				</Grid>
			);
		}
		return data;
	};

	//  <TeamCard name={teamMember.name} description={teamMember.description} mountains={teamMember.favorite_mountains} image={image} />
	render() {
		return (
			<div className={styles.background}>
				<div className={styles.heading}>
					<h1>{this.state.title}</h1>
					<p>{this.state.description}</p>
				</div>
				<Grid container spacing={0}>
					{this.getGrid()}
				</Grid>
			</div>
		);
	}
}
