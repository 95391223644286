import React from 'react';
import ReactDOM from 'react-dom';
import { Route,  BrowserRouter as Router } from 'react-router-dom';

import Impressum from './components/Impressum';
import Loader from './Loader';

const routing = (
  <Router>
    <div>
      <Route exact  path="/" component={Loader } />
      <Route path="/impressum" component={Impressum} />
      
    </div>
  </Router>
)
ReactDOM.render(routing, document.getElementById('root'))