import React, { Component } from 'react';
import '../styles/App.css';
import '../styles/Menu.css';
import Section from './Section';
import AppBar from './AppBar';
import TopPadding from './TopPadding';
import TeamGrid from './TeamGrid';
import ServiceGrid from './ServiceGrid';
import Info from './Info';
import Praxis from './Praxis';
import axios from 'axios';
import HeaderCarousel from './HeaderCarousel.js'


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      slides: []
    }
  }

  componentDidMount() {
    this.setState({
      isLoading: false
    });
    this.loadCarouselData();
  }

  loadCarouselData() {
    axios
      .get("https://api.riedmann.rocks/frauenzimmer/items/title_slider?fields=*.*.*&status=published")
      .then(response => {

        let slides = [];
        response.data.data.forEach(el => {
          let slide = {
            id: el.id,
            maintext: el.hauptinfo,
            subtext: el.nebeninfo,
            subsubtext: el.unterinfo,
            title: el.title,
            image: 'https://api.riedmann.rocks/frauenzimmer/assets/' + el.image.private_hash + '?key=slider',
            dark: el.darkbehindtext
          }
          slides.push(slide);
        });

        this.setState({
          slides: slides
        })

      })
      .catch(error => console.log(error));
  }

  render() {
    if (this.state.isLoading) {
      return (<div>loading</div>);
    } else {
      return (
        <div>
          <AppBar active="0" />
          <Section id="main" style={{ textAlign: 'center' }} >
            <HeaderCarousel slides={this.state.slides} />
          </Section>
          <Section id="team" bgcolor="#454143">
            <TopPadding padding="90">
              <TeamGrid />
            </TopPadding>
          </Section>
          <Section id="praxis" bgcolor="#00675D" classname="praxis">
            <Praxis />
          </Section>
          <Section id="service" bgcolor="#454143">
            <TopPadding padding="90">
              <ServiceGrid />
            </TopPadding>
          </Section>
          <Section id="map" bgcolor="#454143">
            <TopPadding padding="40">
              <div style={{ textAlign: 'center', padding: '50px' }}>
                <h1>Riedgasse 2, 6850 Dornbirn</h1>
                <iframe title="map" className="shadow" width="100%" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=riedgasse%202%20dornbirn&t=&z=17&ie=UTF8&iwloc=&output=embed" scrolling="no" ></iframe>
              </div>
            </TopPadding>
          </Section >


          <Section id="contact" bgcolor="#454143" padding="90">
            <Info />
          </Section>

          <div className="footer">
            <a href="/impressum">Impressum</a>
            <div>created by <a target="_blank" rel="noopener noreferrer" href="https://riedmann.rocks">riedmann.rocks</a></div>
            <div>© Dr. Beate Riedmann</div>

          </div>

        </div >
      );
    }
  }
}

export default App;
