import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TeamCard from './TeamCard';
import axios from 'axios';
import constants from './constants';
import styles from './TeamGrid.module.css';


class TeamGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teamMembers: []
        }
    }

    
    componentDidMount() {
        axios
            .get(constants.TEAM_HEADER_URL)
            .then(response => {

                this.setState({
                    title: response.data.data[0].title,
                    description: response.data.data[0].description
                })

            })
            .catch(error => console.log(error));

        axios
            .get(constants.TEAM_URL)
            .then(response => {
                this.setState({
                    teamMembers: response.data.data
                })
            })
            .catch(error => console.log(error));
    }


    getGrid = () => {
        var data = [];
        for (var i = 0; i < this.state.teamMembers.length; i++) {
            var teamMember = this.state.teamMembers[i];
            
            const image = teamMember.image != null ? teamMember.image.data.thumbnails[7].url : null
            data.push(
                <Grid item xs={12} sm={6} md={4} key={teamMember.id}>
                    <TeamCard name={teamMember.title} description={teamMember.description}  image={image} />
                </Grid>
            )
        }
        return data;
    }

    render() {
        return (
            <div className={styles.background}>
                <h1>{this.state.title}</h1>
                <p>{this.state.description}</p>
                <div className={styles.guidegrid}>
                    <Grid container spacing={24}>
                        {this.getGrid()}
                    </Grid>
                </div>

            </div>
        )
    }
}

export default TeamGrid;